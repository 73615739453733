import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import "../styles/about.scss"
import {BurgerMenuIconAnimation, HeaderLayout} from "../constants/layoutStyle"
import BurgerMenu from "../components/burgerMenu";
import BranchPageLayout from "../components/branchPageLayout";

export default function Home({ data }) {
  const aboutData =
    data.allContentfulAbout.edges[0].node.text.childMarkdownRemark.html
  return (
    <>
      <SEO title="のほ本とは" description="コンセプト、出来ること、メンバー、 etc." />
      <BranchPageLayout>
        <BurgerMenu iconAnination={BurgerMenuIconAnimation.Visible} />
        <div className="about-main">
          <main className="main-area">
            <div
              dangerouslySetInnerHTML={{ __html: aboutData }}
              className="about-textarea"
            />
          </main>
        </div>
      </BranchPageLayout>
    </>
  )
}
export const query = graphql`
  query {
    allContentfulAbout(filter: { node_locale: { eq: "ja" } }) {
      edges {
        node {
          text {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
